import API from "../../configs/api/api.config";

const URL_KARDEX = '/kardex';

export const kardexIdEmpresaRequest = async (id_empresa, pagination) => {
    const request = await API.get(`${URL_KARDEX}/empresa/${id_empresa}`, { params: pagination });
    const response = request.data;
    return response;
}

export const kardexIdEmpresaDatesRequest = async (id_empresa, dates, pagination) => {
    const request = await API.get(`${URL_KARDEX}/empresa/${id_empresa}/start/${dates.start}/end/${dates.end}`, { params: pagination });
    const response = request.data;
    return response;
}

export const kardexIdEmpresaSearchRequest = async (id_empresa, search, pagination) => {
    const request = await API.get(`${URL_KARDEX}/empresa/${id_empresa}/search/${search}`, { params: pagination });
    const response = request.data;
    return response;
}

export const kardexIdProductoRequest = async (id_producto, pagination) => {
    const request = await API.get(`${URL_KARDEX}/producto/${id_producto}`, { params: pagination });
    const response = request.data;
    return response;
}

export const kardexIdRequest = async (id_kardex) => {
    const request = await API.get(URL_KARDEX + '/' + id_kardex);
    const response = request.data;
    return response;
}

export const buscarKardexRequest = async (data) => {
    const request = await API.post(URL_KARDEX + '/buscar', data);
    const response = request.data;
    return response;
}

export const crearKardexRequest = async (data) => {
    const request = await API.post(URL_KARDEX, data);
    const response = request.data;
    return response;
}

export const actualizarKardexRequest = async (id_kardex, data) => {
    const request = await API.patch(URL_KARDEX + '/' + id_kardex, data);
    const response = request.data;
    return response;
}

export const eliminarKardexRequest = async (id_kardex) => {
    const request = await API.delete(URL_KARDEX + '/' + id_kardex);
    const response = request.data;
    return response;
}

export const kardexProductoActualRequest = async (id_producto) => {
    const request = await API.get(`${URL_KARDEX}/producto-actual/${id_producto}`);
    const response = request.data;
    return response;
}