import { Input } from 'antd';
import { memo, useEffect, useState } from 'react';

export const SearchBox = memo(({ placeholder = 'Buscar', onChange = () => {}, isChange, disabled = false }) => {

    const [text, setText] = useState('');

    useEffect(() => {
      console.log('SearchBox ', disabled);
    },[]);

    console.log({ disabled });
    

    useEffect(() => {        
        setText('');
    }, [isChange])

    useEffect(() => {
        const timeout = setTimeout(() => {
          if (text.length > 1) {
            onChange(text);
          }else{
            onChange('');
          }
        }, 500);
    
        return () => {
          clearTimeout(timeout);
        };
      }, [text]);

    const onChangeText = (value) => {
        value = value.target.value;
        setText(value);
    }

    return <Input.Search placeholder={placeholder} value={text} onChange={onChangeText} disabled={disabled}/>

})