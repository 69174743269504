import { createContext, useContext, useEffect, useState } from "react";
import { UserContext } from "./UserContext";

export const ActionContext = createContext({});

export const ActionProvider = ({ children }) => {
  const { empresa } = useContext(UserContext);

  useEffect(() => {
    reloadEmpresas();
    reloadUsuarios();
    reloadSucursales();
    reloadPlanCuentas();
    reloadLineasProductos();
    reloadCategoriasProductos();
    reloadSubCategoriasProductos();
    reloadSubGruposProductos();
    reloadAlmacenes();
    reloadMedidas();
    reloadProductos();
    reloadProveedores();
    reloadGruposProveedores();
    reloadFacturacion();
    reloadCajas();
    reloadSecuencias();
    reloadAgentesVentas();
    reloadGruposClientes();
    reloadZonasClientes();
    reloadRutasClientes();
    reloadClientes();
    reloadCodigosRetenciones();
    reloadBancos();
    reloadCompras();
    reloadGastos();
    reloadProductosGastos();
    reloadBancosClientes();
    reloadGenerarATS();
    reloadServicios();
    reloadKardex();
  }, [empresa]);

  const [isEmpresasChange, setIsEmpresaChange] = useState(false);
  const reloadEmpresas = () => setIsEmpresaChange(!isEmpresasChange);

  const [isUsuariosChange, setIsUsuariosChange] = useState(false);
  const reloadUsuarios = () => setIsUsuariosChange(!isUsuariosChange);

  const [isSucursalesChange, setIsSucursalesChange] = useState(false);
  const reloadSucursales = () => setIsSucursalesChange(!isSucursalesChange);

  const [isPlanCuentasChange, setIsPlanCuentasChange] = useState(false);
  const reloadPlanCuentas = () => setIsPlanCuentasChange(!isPlanCuentasChange);

  const [isLineasProductosChange, setIsLineasProductosChange] = useState(false);
  const reloadLineasProductos = () =>
    setIsLineasProductosChange(!isLineasProductosChange);

  const [isCategoriasProductosChange, setIsCategoriasProductosChange] =
    useState(false);
  const reloadCategoriasProductos = () =>
    setIsCategoriasProductosChange(!isCategoriasProductosChange);

  const [isSubCategoriasProductosChange, setIsSubCategoriasProductosChange] =
    useState(false);
  const reloadSubCategoriasProductos = () =>
    setIsSubCategoriasProductosChange(!isSubCategoriasProductosChange);

  const [isSubGruposProductosChange, setIsSubGruposProductosChange] =
    useState(false);
  const reloadSubGruposProductos = () =>
    setIsSubGruposProductosChange(!isSubGruposProductosChange);

  const [isAlmacenesChange, setAlmacenesChange] = useState(false);
  const reloadAlmacenes = () => setAlmacenesChange(!isAlmacenesChange);

  const [isMedidasChange, setMedidasChange] = useState(false);
  const reloadMedidas = () => setMedidasChange(!isMedidasChange);

  const [isProductosChange, setProductosChange] = useState(false);
  const reloadProductos = () => setProductosChange(!isProductosChange);

  const [isProveedoresChange, setIsProveedoresChange] = useState(false);
  const reloadProveedores = () => setIsProveedoresChange(!isProveedoresChange);

  const [isGruposProveedoresChange, setIsGruposProveedoresChange] =
    useState(false);
  const reloadGruposProveedores = () =>
    setIsGruposProveedoresChange(!isGruposProveedoresChange);

  const [isFacturacionChange, setIsFacturacionChange] = useState(false);
  const reloadFacturacion = () => setIsFacturacionChange(!isFacturacionChange);

  const [isCajasChange, setIsCajasChange] = useState(false);
  const reloadCajas = () => setIsCajasChange(!isCajasChange);

  const [isSecuenciasChange, setIsSecuenciasChange] = useState(false);
  const reloadSecuencias = () => setIsSecuenciasChange(!isSecuenciasChange);

  const [isAgentesVentasChange, setIsAgentesVentasChange] = useState(false);
  const reloadAgentesVentas = () =>
    setIsAgentesVentasChange(!isAgentesVentasChange);

  const [isGruposClientesChange, setIsGruposClientesChange] = useState(false);
  const reloadGruposClientes = () =>
    setIsGruposClientesChange(!isGruposClientesChange);

  const [isZonasClientesChange, setIsZonasClientesChange] = useState(false);
  const reloadZonasClientes = () =>
    setIsZonasClientesChange(!isZonasClientesChange);

  const [isRutasClientesChange, setIsRutasClientesChange] = useState(false);
  const reloadRutasClientes = () =>
    setIsRutasClientesChange(!isRutasClientesChange);

  const [isClientesChange, setIsClientesChange] = useState(false);
  const reloadClientes = () => setIsClientesChange(!isClientesChange);

  const [isCodigosRetencionesChange, setIsCodigosRetencionesChange] =
    useState(false);
  const reloadCodigosRetenciones = () =>
    setIsCodigosRetencionesChange(!isCodigosRetencionesChange);

  const [isBancosChange, setIsBancosChange] = useState(false);
  const reloadBancos = () => setIsBancosChange(!isBancosChange);

  const [isComprasChange, setIsComprasChange] = useState(false);
  const reloadCompras = () => setIsComprasChange(!isComprasChange);

  const [isGastosChange, setIsGastosChange] = useState(false);
  const reloadGastos = () => setIsGastosChange(!isGastosChange);

  const [isProductosGastosChange, setIsProductosGastosChange] = useState(false);
  const reloadProductosGastos = () =>
    setIsProductosGastosChange(!isProductosGastosChange);

  const [isBancosClientesChange, setIsBancosClientesChange] = useState(false);
  const reloadBancosClientes = () =>
    setIsBancosClientesChange(!isBancosClientesChange);

  const [isGenerarATSChange, setIsGenerarATSChange] = useState(false);
  const reloadGenerarATS = () => setIsGenerarATSChange(!isGenerarATSChange);

  const [isServiciosChange, setIsServiciosChange] = useState(false);
  const reloadServicios = () => setIsServiciosChange(!isServiciosChange);

  const [isKardexChange, setIsKardexChange] = useState(false);
  const reloadKardex = () => setIsKardexChange(!isKardexChange);

  return (
    <ActionContext.Provider
      value={{
        reloadEmpresas,
        isEmpresasChange,

        reloadUsuarios,
        isUsuariosChange,

        reloadSucursales,
        isSucursalesChange,

        reloadPlanCuentas,
        isPlanCuentasChange,

        reloadLineasProductos,
        isLineasProductosChange,

        reloadCategoriasProductos,
        isCategoriasProductosChange,

        reloadSubCategoriasProductos,
        isSubCategoriasProductosChange,

        reloadSubGruposProductos,
        isSubGruposProductosChange,

        reloadAlmacenes,
        isAlmacenesChange,

        reloadMedidas,
        isMedidasChange,

        reloadProductos,
        isProductosChange,

        reloadProveedores,
        isProveedoresChange,

        reloadGruposProveedores,
        isGruposProveedoresChange,

        reloadFacturacion,
        isFacturacionChange,

        reloadCajas,
        isCajasChange,

        reloadCajas,
        isCajasChange,

        reloadSecuencias,
        isSecuenciasChange,

        reloadAgentesVentas,
        isAgentesVentasChange,

        reloadGruposClientes,
        isGruposClientesChange,

        reloadZonasClientes,
        isZonasClientesChange,

        reloadRutasClientes,
        isRutasClientesChange,

        reloadClientes,
        isClientesChange,

        reloadCodigosRetenciones,
        isCodigosRetencionesChange,

        reloadBancos,
        isBancosChange,

        reloadCompras,
        isComprasChange,

        reloadGastos,
        isGastosChange,

        reloadProductosGastos,
        isProductosGastosChange,

        reloadBancosClientes,
        isBancosClientesChange,

        reloadGenerarATS,
        isGenerarATSChange,

        reloadServicios,
        isServiciosChange,

        reloadKardex,
        isKardexChange,
      }}
    >
      {children}
    </ActionContext.Provider>
  );
};
