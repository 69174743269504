import { Button, Row, Table, Tooltip } from "antd";
import React, { useContext, useRef, useState } from "react";
import { CiEdit, CiTrash } from "react-icons/ci";
import { useNavigate } from "react-router-dom";
import { ActionContext } from "../../../../../context/ActionContext";
import { UserContext } from "../../../../../context/UserContext";
import useColumnSearch from "../../../../../hooks/useColumnSearch";
import { eliminarKardexRequest } from "../../../../../services/api/kardex";
import { dateDDMMYYYYHHmm } from "../../../../../helpers/format/dates-formater";
import { numberFormat } from "../../../../../helpers/format/numbers-formater";

export const TablaKardex = ({ data }) => {

  const { getColumnSearchProps } = useColumnSearch();
  const { checkPermisos, empresa, checkData } = useContext(UserContext);
  const { reloadKardex } = useContext(ActionContext);

  const tableRef = useRef(null);

  const [id, setId] = useState(0);

  const navigate = useNavigate();

  const [openEdit, setOpenEdit] = useState(false);

  const showDrawerEdit = () => {
    setOpenEdit(true);
  };

  const onCloseEdit = () => {
    setOpenEdit(false);
  };

  const editar = (record) => {
    const id_kardex = record.id_kardex;
    if (!id_kardex) return;
    setId(id_kardex);
    showDrawerEdit();
  };

  const eliminar = (record) => {
    const id_kardex = record.id_kardex;
    if (!id_kardex) return;
    const confirm = window.confirm(
      "¿Esta seguro de eliminar este Kardex esta acción podría ser irreversible y tener consecuencias significativas en múltiples niveles?"
    );
    if (!confirm) return;

    eliminarKardexRequest(id_kardex).then((res) => {
      reloadKardex();
    });
  };

  const columns = [
    {
      title: "F. Registro",
      dataIndex: "fecha_movimiento_kardex",
      key: "fecha_movimiento_kardex",
      width: "15%",
      render: (data) => dateDDMMYYYYHHmm(data),
    },
    {
      title: "D. Referencia",
      dataIndex: "documento_referencia",
      key: "documento_referencia",
      width: "25%",
    },
    {
      title: "Codigo Retención",
      dataIndex: "codigo_retencion",
      key: "codigo_retencion",
      width: "15%",
      render: (record) => record?.codigo,
    },
    {
      title: "D. Retencion",
      dataIndex: "codigo_retencion",
      key: "codigo_retencion",
      width: "25%",
      render: (record) => record?.descripcion,
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "x",
      width: "10%",
      render: (record) => (
        <Row justify={"space-around"}>
          <Tooltip title={"Editar"}>
            <Button
              disabled={!checkPermisos("MGASTOS").editar}
              shape="default"
              size="small"
              icon={<CiEdit />}
              onClick={() => editar(record)}
            />
          </Tooltip>
          <Tooltip title={"Eliminar"}>
            <Button
              danger
              shape="default"
              size="small"
              disabled={!checkPermisos("MGASTOS").eliminar}
              onClick={() => eliminar(record)}
              icon={<CiTrash />}
            />
          </Tooltip>
        </Row>
      ),
    },
  ];

  const renderData = (data, id, key) => {
    return data?.tipo_movimiento_kardex?.id_tipo_movimiento_kardex === id? numberFormat(data?.[key] ?? 0) : numberFormat(0); 
  }

  return (
    <Table
      scroll={{
        y: 450,
      }}
      rowKey={"id_kardex"}
      dataSource={data.map((d) => ({ ...d, children: d.subcuentas }))}
      size="small"
      pagination
    >
      <Table.Column title="F. Registro" dataIndex="fecha_movimiento_kardex" key="fecha_movimiento_kardex" render={(value) => dateDDMMYYYYHHmm(value)} width={'10%'}/>
      <Table.Column title="Producto" dataIndex="producto" key="porducto" width={'10%'} render={(value) => value.descripcion}/>
      <Table.Column title="D. Referencia" dataIndex="documento_referencia" key="documento_referencia" width={'10%'}/>
      <Table.ColumnGroup title="Ingreso">
        <Table.Column title="Cantidad" dataIndex="cantidad_movimiento" key="cantidad_movimiento" render={(_, record) => renderData(record, 1, "cantidad_movimiento")} width={'7%'} align="right" />
        <Table.Column title="Costo" dataIndex="costo_movimiento" key="costo_movimiento" render={(_, record) => renderData(record, 1, "costo_movimiento")} width={'7%'} align="right" />
        <Table.Column title="Total" dataIndex="total_movimiento" key="total_movimiento" render={(_, record) => renderData(record, 1, "total_movimiento")} width={'7%'} align="right" />
      </Table.ColumnGroup>
      <Table.ColumnGroup title="Egreso">
      <Table.Column title="Cantidad" dataIndex="cantidad_movimiento" key="cantidad_movimiento" render={(_, record) => renderData(record, 2, "cantidad_movimiento")} width={'7%'} align="right" />
        <Table.Column title="Costo" dataIndex="costo_movimiento" key="costo_movimiento" render={(_, record) => renderData(record, 2, "costo_movimiento")} width={'7%'} align="right" />
        <Table.Column title="Total" dataIndex="total_movimiento" key="total_movimiento" render={(_, record) => renderData(record, 2, "total_movimiento")} width={'7%'} align="right" />
      </Table.ColumnGroup>
      <Table.ColumnGroup title="Saldo">
      <Table.Column title="Cantidad" dataIndex="cantidad_fin" key="cantidad_fin" width={'7%'} align="right" />
        <Table.Column title="Costo" dataIndex="costo_fin" key="costo_fin" width={'7%'} align="right" />
        <Table.Column title="Total" dataIndex="total_fin" key="total_fin" width={'7%'} align="right" />
      </Table.ColumnGroup>
      <Table.Column title="Almacen" dataIndex="almacen" key="almacen" render={(data) => data.nombre_almacen} width={'7%'}/>
    </Table>
  );
};
