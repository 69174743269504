import {
  Breadcrumb,
  Button,
  Col,
  Row,
  Spin,
  Tooltip
} from "antd";
import { Content } from "antd/es/layout/layout";
import React, { useContext, useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { MdFireTruck } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { ActionContext } from "../../../../context/ActionContext";
import { SidebarContext } from "../../../../context/SidebarContext";
import { UserContext } from "../../../../context/UserContext";
import { DateFilter } from "../../../../global/components/DateFilter";
import { SearchBox } from "../../../../global/components/SearchBox";
import { usePagination } from "../../../../hooks/usePagination";
import {
  buscarKardexRequest,
  kardexIdEmpresaDatesRequest,
  kardexIdEmpresaRequest,
  kardexIdEmpresaSearchRequest,
  kardexIdProductoRequest,
} from "../../../../services/api/kardex";
import { CrearGasto } from "./components/CrearGasto";
import { TablaKardex } from "./components/TablaKardex";
import { ProductoInput } from "../productos/components/ProductoInput";
import { isNullOrUndefined } from "../../../../helpers/functions/is-null-or-undefined.function";
import { isStringEmpty } from "../../../../helpers/functions/is-string-empty.function";


export const KardexPage = () => {

  const { isKardexChange } = useContext(ActionContext);
  const { handleOpen, handleClose } = useContext(SidebarContext);
  const { checkPermisos, empresa } = useContext(UserContext);

  const navigate = useNavigate();

  const [kardex, setKardex] = useState([]);
  const [kardexSearch, setKardexSearch] = useState([]);
  const [textSearch, setTextSearch] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);

  const { page, limit, onChangeTablePagination, reset } = usePagination();

  const [dates, setDates] = useState(null);
  const [search, setSearch] = useState('');
  const [productoSeleccionado, setProductoSeleccionado] = useState(null);

  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    handleOpen();
    return () => {
      handleClose();
    };
  }, []);

  useEffect(() => {
    reset();
    setDates(null);
    setSearch('');
    setKardex([]);
    setProductoSeleccionado(null);
  }, [isKardexChange]);

  useEffect(() => {
    console.log('useEffect ');

    reset();
    setIsLoading(true);
    const pagination = {
      limit,
      page: 1,
    }
    kardexRequest(pagination)
      .then((res) => {
        console.log({ res });
        setKardex(res.data);
      })
      .finally(() => setIsLoading(false));
  }, [isKardexChange, dates, search, productoSeleccionado]);

  useEffect(() => {
    if (page === 1) return;
    setIsLoading(true);
    const pagination = {
      limit,
      page,
    }
    kardexRequest(pagination)
      .then((res) => {
        setKardex((prev) => [...prev, ...res.data]);
      })
      .finally(() => setIsLoading(false));

  }, [page]);

  const kardexRequest = async (pagination) => {
    if (isNullOrUndefined(dates) && !search && !productoSeleccionado) {
      return await kardexIdEmpresaRequest(empresa.id_empresa, pagination)
    }
    if (!isNullOrUndefined(dates)) {
      setSearch('');
      return await kardexIdEmpresaDatesRequest(empresa.id_empresa, dates, pagination)
    }
    if (!!search) {
      setDates(null);
      return await kardexIdEmpresaSearchRequest(empresa.id_empresa, search, pagination)
    }

    if (!!productoSeleccionado) {
      setSearch('');
      setDates(null);
      return await kardexIdProductoRequest(productoSeleccionado?.id_producto, pagination)
    }
  }

  useEffect(() => {
    if (textSearch.length > 1) {
      const dataRequest = {
        id_empresa: empresa.id_empresa,
        busqueda: textSearch,
      };
      setIsSearching(true);
      buscarKardexRequest(dataRequest)
        .then((res) => setKardexSearch(res))
        .finally(() => {
          setIsSearching(false);
        });
    }
  }, [textSearch, isKardexChange]);

  const onChangeDate = (value) => setDates(value);

  const onChangeSearch = (value) => setSearch(value);

  const disabledSearchBox = () => {
    return productoSeleccionado !== null || dates !== null;
  };

  const disabledProductoInput = () => {
    return search !== '' || dates !== null;
  };

  const disabledDateFilter = () => {
    return productoSeleccionado !== null || search !== '';
  };


  return (
    <Spin spinning={isLoading}>
      <Content>
        {/* Header */}
        <Row
          align={"middle"}
          style={{ marginBottom: 5 }}
          justify={"space-between"}
        >
          <Col span={8}>
            <Row align={"middle"}>
              <Tooltip title={"Regresar"}>
                <Button
                  onClick={() => navigate(-1)}
                  type="text"
                  shape="circle"
                  icon={<IoArrowBack size={20} />}
                  style={{ marginRight: 10 }}
                />
              </Tooltip>
              <Breadcrumb
                items={[
                  {
                    title: (
                      <Row align={"middle"}>
                        <MdFireTruck style={{ marginRight: 5 }} />
                        <span>Compras</span>
                      </Row>
                    ),
                  },
                  {
                    title: "Kardex",
                  },
                ]}
              />
            </Row>
          </Col>
          <Col span={16}>
            <Row gutter={10} align={"middle"}>
              <Col span={12}>
                <ProductoInput
                  label={'Producto: '}
                  value={productoSeleccionado}
                  onChange={(p) => setProductoSeleccionado(p)}
                  eliminarProducto={() => setProductoSeleccionado(null)}
                  disabled={disabledProductoInput()}
                />
              </Col>
              <Col span={5}>
                <SearchBox isChange={isKardexChange} onChange={onChangeSearch} disabled={disabledSearchBox()} />
              </Col>
              <Col span={7}>
                <DateFilter isChange={isKardexChange} onChange={onChangeDate} disabled={disabledDateFilter()} />
              </Col>
            </Row>
          </Col>
        </Row>
        {/* Tabla */}

        {textSearch.length > 1 ? (
          <TablaKardex data={kardexSearch} />
        ) : (
          <TablaKardex data={kardex} />
        )}
        {/* Crear Drawer 
    
        <CrearGasto open={open} showDrawer={showDrawer} onClose={onClose} />
        */}
      </Content>
    </Spin>
  );
};
