import { IoCart, IoSettingsSharp } from "react-icons/io5";
import { MdFireTruck } from "react-icons/md";
import { VscGraph } from "react-icons/vsc";
import { GiTwoCoins } from "react-icons/gi";
import { FaWallet } from "react-icons/fa";

export const usuarioMenu = [
  //ADMINISTRACION
  {
    key: "SADMINISTRACION",
    label: "Administración",
    icon: <IoSettingsSharp />,
    children: [
      {
        key: "MEMPRESAS",
        label: "Empresas",
        navigate: "/administracion/empresas",
      },
      {
        key: "MUSUARIOS",
        label: "Usuarios",
        navigate: "/administracion/usuarios",
      },
      {
        key: "MPARAMETROS-PRODUCTOS",
        label: "Parámetros Productos",
        navigate: "/administracion/parametros-productos",
      },
      {
        key: "MPARAMETROS-CONTABLES",
        label: "Parámetros Contables",
        navigate: "/administracion/parametros-contables",
      },
      {
        key: "MPARAMETROS-FACTURACION",
        label: "Parámetros Facturación",
        navigate: "/administracion/parametros-facturacion",
      },
      {
        key: "MPARAMETROS-FACTURACION-ELECTRONICA",
        label: "Parámetros Facturación Electrónica",
        navigate: "/administracion/parametros-facturacion-electronica",
      },
      {
        key: "MSUCURSALES",
        label: "Sucursales",
        navigate: "/administracion/sucursales",
      },
    ],
  },
  //VENTAS
  {
    key: "SVENTAS",
    label: "Ventas",
    icon: <IoCart />,
    children: [
      {
        key: "MFACTURACION",
        label: "Facturación",
        navigate: "/ventas/facturacion",
      },
      {
        key: "MFACTURACION-TRANSPORTISTA",
        label: "Facturación Trasportista",
        navigate: "/ventas/facturacion-transportista",
      },
      // {
      //   key: "MPROFORMAS",
      //   label: "Proformas",
      //   navigate: "/ventas/proformas",
      // },
      {
        key: "MCLIENTES",
        label: "Clientes",
        navigate: "/ventas/clientes",
      },
      {
        key: "MGRUPOS-CLIENTES",
        label: "Grupos Clientes",
        navigate: "/ventas/grupos-clientes",
      },
      {
        key: "MZONAS-CLIENTES",
        label: "Zonas Clientes",
        navigate: "/ventas/zonas-clientes",
      },
      {
        key: "MRUTAS-CLIENTES",
        label: "Rutas Clientes",
        navigate: "/ventas/rutas-clientes",
      },
      {
        key: "MSECUENCIAS",
        label: "Secuencias",
        navigate: "/ventas/secuencias",
      },
      {
        key: "MSECUENCIAS-TRANSPORTISTA",
        label: "Secuencias Transportista",
        navigate: "/ventas/secuencias-transportista",
      },
      {
        key: "MAGENTES-VENTAS",
        label: "Agentes de Ventas",
        navigate: "/ventas/agentes-ventas",
      },
    ],
  },
  //COMPRAS
  {
    key: "SCOMPRAS",
    label: "Compras",
    icon: <MdFireTruck />,
    children: [
      {
        key: "MCOMPRAS",
        label: "Compras",
        navigate: "/compras/compras",
      },
      {
        key: "MPROVEEDORES",
        label: "Proveedores",
        navigate: "/compras/proveedores",
      },
      {
        key: "MGRUPOS-PROVEEDORES",
        label: "Grupos Proveedores",
        navigate: "/compras/grupos-proveedores",
      },
      {
        key: "MPRODUCTOS",
        label: "Productos",
        navigate: "/compras/productos",
      },
      {
        key: "MSERVICIOS",
        label: "Servicios",
        navigate: "/compras/servicios",
      },
      {
        key: "MGASTOS",
        label: "Gastos",
        navigate: "/compras/gastos",
      },
      {
        key: "MKARDEX",
        label: "Kardex",
        navigate: "/compras/kardex",
      },
      {
        key: "MLINEAS-PRODUCTOS",
        label: "Línea de Productos",
        navigate: "/compras/lineas-productos",
      },
      {
        key: "MCATEGORIAS-PRODUCTOS",
        label: "Categorias de Productos",
        navigate: "/compras/categorias-productos",
      },
      {
        key: "MSUB-CATEGORIAS-PRODUCTOS",
        label: "Sub Categorias de Productos",
        navigate: "/compras/sub-categorias-productos",
      },
      {
        key: "MSUB-GRUPOS-PRODUCTOS",
        label: "Sub Grupos de Productos",
        navigate: "/compras/sub-grupos-productos",
      },
      {
        key: "MALMACENES",
        label: "Almacenes",
        navigate: "/compras/almacenes",
      },
      {
        key: "MMEDIDAS",
        label: "Medidas",
        navigate: "/compras/medidas",
      },
    ],
  },
  //TESORERIA
  {
    key: "STESORERIA",
    label: "Tesorería",
    icon: <GiTwoCoins />,
    children: [
      {
        key: "MCAJAS",
        label: "Cajas",
        navigate: "/tesoreria/cajas",
      },
      {
        key: "MBANCOS",
        label: "Bancos",
        navigate: "/tesoreria/bancos",
      },
    ],
  },
  //CARTERA
  {
    key: "SCARTERA",
    label: "Cartera",
    icon: <FaWallet />,
    children: [
      {
        key: "MBANCOS-CLIENTES",
        label: "Clientes Bancos",
        navigate: "/cartera/bancos-clientes",
      },
    ],
  },
  //CONTABILIDAD
  {
    key: "SCONTABILIDAD",
    label: "Contabilidad",
    icon: <VscGraph />,
    children: [
      {
        key: "MPLAN-CUENTAS",
        label: "Plan de cuentas",
        navigate: "/contabilidad/plan-cuentas",
      },
      {
        key: "MCODIGOS-RETENCIONES",
        label: "Códigos de Retenciones",
        navigate: "/contabilidad/codigos-retenciones",
      },
      {
        key: "MGENERAR-ATS",
        label: "Generar ATS",
        navigate: "/contabilidad/generar-ats",
      },
    ],
  },
];
